import React from 'react';
import { RefreshCw } from 'lucide-react';
import { FiAlertCircle } from 'react-icons/fi';

interface ErrorMessageProps {
    readonly msg: string;
}

export function ErrorMessage({ msg }: ErrorMessageProps) {
    const handleRetry = () => {
        window.location.reload();
    };

    return (
        <div className="flex items-center justify-center min-h-[300px] w-full">
            <div className="bg-white border border-gray-200 p-6 rounded-lg shadow-lg max-w-2xl w-full">
                <div className="flex items-center mb-4">
                    <FiAlertCircle className="text-gray-800 w-6 h-6 mr-3" />
                    <h2 className="text-xl font-semibold text-gray-800">Ups, algo no salió como esperábamos</h2>
                </div>
                <p className="text-gray-700 mb-4">{msg}</p>
                <p className="text-gray-500 mb-6">
                    No te preocupes, estas cosas pasan. Intentemos recargar la página para ver si podemos solucionarlo.
                </p>
                <div className="flex justify-between items-center">
                    <button
                        onClick={handleRetry}
                        className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-lg inline-flex items-center transition duration-150 ease-in-out shadow-md"
                    >
                        <RefreshCw className="w-4 h-4 mr-2" />
                        Recargar página
                    </button>
                    <a
                        href="https://clidrive.slack.com/channels/x-connect-ops"
                        className="text-blue-600 hover:text-blue-700 underline font-medium"
                    >
                        Necesito ayuda
                    </a>
                </div>
            </div>
        </div>
    );
}
