import { useStorage } from '@/hooks/useStorage';
import { objectToQueryString } from '@/lib/utils/queryParams';
import { mapNaN } from '@/lib/utils/values';
import { SelectOption, StageC2C, tiers } from '@/types';
import { Statistics } from '@/types/statistics';
import { DashboardRedirectProps } from '@/views/dashboard/DashboardView';
import { FilterSalesAgentDashboardForm } from '@/views/dashboard/forms';
import { transformStagesToObject } from '@/views/dashboard/helpers';
import { DashboardTable } from '@/views/dashboard/tables';
import { Table } from 'flowbite-react';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { FaSackDollar } from 'react-icons/fa6';
import { HiClipboardList, HiOutlineClock } from 'react-icons/hi';
import { TbLockDollar } from 'react-icons/tb';
import { useDealListPageData } from '@/views/deals/list/useDealListQuery';

export type C2CSalesStageStatistics = Record<StageC2C, number>;

export interface C2CSalesSectionProps {
    readonly data: Statistics;
    readonly redirectToDeals: (props: DashboardRedirectProps) => void;
}

export function C2CSalesSection({ data, redirectToDeals }: C2CSalesSectionProps) {
    const router = useRouter();
    const { setItem } = useStorage();
    const [salesAgent, setSalesAgent] = useState<SelectOption<string> | null>(null);
    const stages = transformStagesToObject(data.deals.stages);
    const { data: dealReservations, refetch } = useDealListPageData({
        reservationStatuses: 'RECEIVED',
        situation: 'OPEN',
        ...(salesAgent && {
            salesAgentUuid: salesAgent?.value,
            label: salesAgent?.label,
        }),
    });

    const handleClickCard = (stage: StageC2C) => {
        redirectToDeals({
            tier: tiers.C2C,
            filters: {
                situation: 'OPEN',
                stage,
                ...(salesAgent && {
                    salesAgentUuid: salesAgent?.value,
                    label: salesAgent?.label,
                }),
            },
        });
    };

    const redirectToDealsReservations = () => {
        setItem('dealTier', 'C2C');
        setItem(
            'dealsInitialParams',
            objectToQueryString({
                reservationStatuses: 'RECEIVED',
                situation: 'OPEN',
                ...(salesAgent && {
                    salesAgentUuid: salesAgent?.value,
                    label: salesAgent?.label,
                }),
            }),
        );

        router.push('/deals');
    };

    const qualifications: C2CSalesStageStatistics = {
        LEAD_CREATED: mapNaN(stages.LEAD_CREATED),
        LEAD_SENT_TO_BOT: mapNaN(stages.LEAD_SENT_TO_BOT),
        LEAD_PENDING_CALL: mapNaN(stages.LEAD_PENDING_CALL),
        LEAD_MANAGED_WITHOUT_CALL: mapNaN(stages.LEAD_MANAGED_WITHOUT_CALL),
        LEAD_CALLED: mapNaN(stages.LEAD_CALLED),
        LEAD_PRE_QUOTED: mapNaN(stages.LEAD_PRE_QUOTED),
        LEAD_QUOTED: mapNaN(stages.LEAD_QUOTED),
    };

    const submissions: C2CSalesStageStatistics = {
        DEAL_CREATED: mapNaN(stages.DEAL_CREATED),
        DEAL_WAITING: mapNaN(stages.DEAL_WAITING),
        DEAL_IN_ANALYSIS: mapNaN(stages.DEAL_IN_ANALYSIS),
        DEAL_PRE_APPROVED: mapNaN(stages.DEAL_PRE_APPROVED),
        DEAL_APPROVED: mapNaN(stages.DEAL_APPROVED),
        DEAL_QUALIFIED: mapNaN(stages.DEAL_QUALIFIED),
    };

    return (
        <div className="space-y-8">
            <div className="flex gap-8 items-center">
                <h2 className="font-semibold text-2xl">Ventas</h2>
                <FilterSalesAgentDashboardForm
                    tier={tiers.C2C}
                    setSalesAgent={setSalesAgent}
                    refetchDealReservations={refetch}
                />
            </div>
            <h2 className="flex gap-2 items-center p-2 bg-white rounded-lg font-semibold text-xl">
                <HiOutlineClock /> En proceso
            </h2>
            <div className="grid grid-cols-2 gap-16 p-2">
                <DashboardTable
                    handleClickCard={handleClickCard}
                    title="Cualificación"
                    fields={qualifications}
                    icon={<HiClipboardList className="w-6 h-6" />}
                />
                <DashboardTable
                    handleClickCard={handleClickCard}
                    title="Financiación"
                    fields={submissions}
                    icon={<FaSackDollar className="w-5 h-5" />}
                />

                <button type="button" className="w-full" onClick={redirectToDealsReservations}>
                    <Table striped>
                        <Table.Head className="hover:cursor-pointer">
                            <Table.HeadCell className="flex items-center gap-2 rounded-l-lg uppercase">
                                <TbLockDollar className="w-6 h-6" />
                                Reservas recibidas
                            </Table.HeadCell>
                            <Table.HeadCell className="rounded-r-lg">{dealReservations?.totalItems}</Table.HeadCell>
                        </Table.Head>
                    </Table>
                </button>
            </div>
        </div>
    );
}
